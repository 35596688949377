import './Header.scss'

export function Header() {
    return (
        <div id="header">
            <div></div>
            <div>Kieran Ghataora</div>
            <div></div>
        </div>
    )
}