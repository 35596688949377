import React from 'react';
import './App.scss';
import {Header} from "./Components/Header/";
import {Content} from "./Components/Content/Content";
import {Footer} from "./Components/Footer/Footer";

function App() {
    return (
        <div className="App">
            <Header/>
            <Content />
            <Footer />
        </div>
    );
}

export default App;
